// scss-lint:disable ImportantRule
@import 'settings';

$margin: 15mm;

@media print {
  * {
    // scss-lint:disable ImportantRule, PropertySpelling
    color-adjust: exact !important; // Firefox 48 – 96
    -webkit-print-color-adjust: exact !important; // Chrome, Safari 6 – 15.3, Edge
    print-color-adjust: exact !important; // Firefox 97+, Safari 15.4+
  }

  // page margins
  @page {
    margin: 0;
  }

  table {
    page-break-before: always;
  }

  thead {
    display: table-header-group;
    height: $margin;
  }

  tfoot {
    display: table-footer-group;
    height: $margin;
  }

  tbody {
    section {
      margin: 0 $margin;
    }
  }

  // global styles
  // scss-lint:disable QualifyingElement
  a[href]::after {
    // scss-lint:disable ImportantRule
    content: none !important;
  }

  .grid-container {
    padding: 0;
  }

  .shadow {
    box-shadow: $prototype-box-shadow !important;
  }

  .quote-images {
    margin: 0;
  }

  section {
    &.padding-0 {
      page: page-with-no-margins;
    }

    &:last-child {
      page-break-after: avoid;
    }
  }

  .card-divider {
    page-break-after: avoid;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .chapter-title {
    font-size: 1.1rem;
    line-height: 1.5rem;
    page-break-after: avoid;
  }

  a {
    font-family: monospace;
    text-decoration: none;
    word-wrap: break-word;
  }

  .compass-background-bottom {
    bottom: 0;
    position: fixed;
    right: 0;
    visibility: visible;
  }

  .cover-images {
    bottom: 0;
    display: block;
    position: absolute;
    right: 0;
    width: 90%;
  }

  .cover-background {
    height: auto;
    width: 100%;
  }

  .report-background {
    position: fixed;
    right: 0;
    top: 0;
  }

  // chart
  .chart {
    margin: 3rem 0;
  }

  // report
  .report {
    a {
      font-size: .7rem;
    }

    p {
      font-size: .85rem;
    }
  }

  .quote-callout {
    border-left: 10px solid $primary-color;
  }
}
