@import '../settings';

.environment-ribbon-wrapper {
  height: 150px;
  left: -40px;
  pointer-events: none;
  position: absolute;
  top: 25px;
  width: 150px;
  z-index: 999999;
}

.environment-ribbon {
  background: get-color(alert);
  font-family: Sans-Serif, $body-font-family;
  font-size: 12px;
  position: relative;
  text-align: center;
  transform: rotate(-45deg);
}
