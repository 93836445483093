@import '../settings';

.background-logo {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
}

.text {
  color: $medium-gray;
  font-family: calluna, serif;
}

.home-page-heading {
  font-family: soleil, serif;
  font-weight: bold;
  margin-bottom: 2rem;
}

.heading {
  font-family: soleil, serif;
  font-weight: bold;
}
