@import '../settings';

.slidecontainer {
  position: relative;
  width: 100%;
}

.sliding-scale {
  max-width: 500px;
  min-width: 400px;
}

.slider {
  appearance: none;
  border-radius: 5px;
  font-family: soleil, serif;
  font-style: italic;
  height: 15px;
  margin: 2rem 0;
  opacity: 1;
  outline: none;
  transition: opacity .2s;
  width: 100%;
  z-index: 1;

}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  background: darken($primary-color, 10%);
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.slider::-moz-range-thumb {
  appearance: none;
  background: darken($primary-color, 10%);
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.note {
  color: $medium-gray;
  font-family: soleil, serif;
  font-size: .8rem;
}

.ticks {
  display: flex;
  justify-content: space-between;
  padding: 0 9px;
  position: absolute;
  top: 33px;
  width: 100%;

  .tick {
    border-top: 15px solid transparent;
    position: relative;
    width: 6px;
  }

  .tick::before {
    background-color: $primary-color;
    content: ' ';
    height: 15px;
    left: 4px;
    position: absolute;
    top: -13px;
    width: 1px;
  }
}
