// scss-lint:disable ImportantRule PropertySortOrder PropertySpelling
@import '../settings';

.section-title {
  font-family: 'din-condensed', serif;
  font-weight: bold;
  text-transform: uppercase;
}

.chapter-title {
  color: $primary-color;
  font-family: soleil, serif;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.2rem;
  letter-spacing: .01125rem;
}

.card-title {
  border-bottom: 2px solid $warning-color;
}

.avoid-break-inside {
  break-inside: avoid;
}

.report {
  .callout {
    li {
      margin-bottom: 1rem;

      // scss-lint:disable NestingDepth
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    font-family: calluna, serif;
    font-size: 1.3rem;
  }
}
