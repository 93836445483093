@import './settings';

@font-face {
  font-family: 'soleil'; // Give a name to your font
  src: local('soleil'), // Use local() to refer to the font by name if it's installed on the user's device
  url('../fonts/Soleil/SoleilRegular.otf') format('opentype'); // Path to your font file
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'soleil'; // Give a name to your font
  src: local('soleil'), // Use local() to refer to the font by name if it's installed on the user's device
  url('../fonts/Soleil/SoleilBold.otf') format('opentype'); // Path to your font file
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'soleil'; // Give a name to your font
  src: local('soleil'), // Use local() to refer to the font by name if it's installed on the user's device
  url('../fonts/Soleil/SoleilBook.otf') format('opentype'); // Path to your font file
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'soleil'; // Give a name to your font
  src: local('soleil'), // Use local() to refer to the font by name if it's installed on the user's device
  url('../fonts/Soleil/SoleilLight.otf') format('opentype'); // Path to your font file
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'calluna'; // Give a name to your font
  src: local('calluna'), // Use local() to refer to the font by name if it's installed on the user's device
  url('../fonts/Calluna/Calluna-Regular.otf') format('opentype'); // Path to your font file
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'din-condensed'; // Give a name to your font
  src: local('din-condensed'), // Use local() to refer to the font by name if it's installed on the user's device
  url('../fonts/DinPro/DINPro-Medium.otf') format('opentype'); // Path to your font file
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'din-condensed'; // Give a name to your font
  src: local('din-condensed'), // Use local() to refer to the font by name if it's installed on the user's device
  url('../fonts/DinPro/DINPro-CondBold.otf') format('opentype'); // Path to your font file
  font-weight: bold;
  font-style: normal;
}

.column-count-2 {
  column-count: 2;
  column-gap: 3rem;
  margin-bottom: 1rem;

  p:last-of-type {
    margin-bottom: 0;
  }
}

.primary-background {
  background-color: $primary-color;
}

.primary-color {
  color: $primary-color;
}

.beige-background {
  background-color: $beige;
}

.white-background {
  background-color: $white;
}

.line-height-1 {
  line-height: 1;
}

.collapse-grid-container {
  margin-left: - map-get($grid-padding-gutters, 'small') / 2;
  margin-right: - map-get($grid-padding-gutters, 'small') / 2;

  @include breakpoint(medium) {
    margin-left: - map-get($grid-padding-gutters, 'medium') / 2;
    margin-right: - map-get($grid-padding-gutters, 'medium') / 2;
  }
}
