@import '../settings';

.top-bar {
  border-top: 5px solid $primary-color;
  font-family: din-condensed, serif;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: .02rem;
  line-height: 2.125rem;
  padding: 1rem;
  text-transform: uppercase;

  @include breakpoint(medium) {
    font-size: 2rem;
  }

  ul {
    background-color: $beige;
  }

  .reset-button {
    background-color: $white;
    border: 1px solid $light-gray;
    box-shadow: 0 1px 2px 0 $dark-gray;
    color: $medium-gray;
    font-family: soleil, serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    padding: .5 * $global-padding;
  }

  .version-date-text {
    color: $medium-gray;
    font-family: soleil, serif;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
  }
}

.top-bar-report {
  border-top: 10px solid $primary-color;
  padding: 2rem;

  .cover-logo {
    margin-bottom: 1rem;
  }

  .cover-title {
    font-family: din-condensed, serif;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .cover-text {
    color: $medium-gray;
    font-family: soleil, serif;
    font-size: .8rem;
    line-height: 1.25rem;
  }

  .patient-name {
    color: $primary-color;
    font-family: calluna, serif;
    font-size: 1.125rem;
  }
}
