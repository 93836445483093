@import '../settings';

.compass-background {
  visibility: hidden;
}

.compass-background-bottom {
  visibility: hidden;
}

.compass-star {
  position: absolute;
  right: 50px;
  top: -150px;
}

.cover-images {
  position: relative;
}
