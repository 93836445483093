@import '../settings';

.chart {
  background-color: $beige;
  font-family: 'soleil', serif;
  height: auto;
  margin: 1rem;
  width: 100%;
}

.chart-container {
  margin: 5%;
}
