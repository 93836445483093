@import '../settings';


.checkbox-cells {
  padding-bottom: 8px;
}

.checkbox-margin {
  margin-bottom: .5rem;
}

// default checkbox amd customized checkbox container
.checkbox-container {
  cursor: pointer;
  display: block;
  font-family: soleil, serif;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 12px;
  padding-left: 35px;
  position: relative;

  // When the checkbox is checked, add a light primary color background
  // scss-lint:disable QualifyingElement
  input[type='checkbox']:checked ~ .checkmark {
    background-color: lighten($primary-color, 50%);
    border: 2px solid $primary-color;
  }

  // Style the checkmark/indicator
  .checkmark::after {
    border: solid $primary-color;
    border-radius: 1px;
    border-width: 0 3px 3px 0;
    height: 13px;
    left: 8px;
    top: 3px;
    transform: rotate(45deg);
    width: 5px;
  }
}

// default checkbox
// scss-lint:disable QualifyingElement
input[type='checkbox'] {
  cursor: pointer;
  height: 25px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 25px;
  z-index: 1;
}

// customized checkbox
.checkmark {
  background-color: transparent;
  border: 2px solid $light-gray;
  border-radius: 8px;
  height: 25px;
  left: 0;
  position: absolute;
  top: -7px;
  width: 25px;
}

// When the checkbox is hovered over, add a light primary color background
// scss-lint:disable QualifyingElement
.checkbox-container:hover input[type='checkbox'] ~ .checkmark {
  background-color: lighten($primary-color, 50%);
}


// checkmark/indicator should be hidden by default
.checkmark::after {
  content: ' ';
  display: none;
  position: absolute;
}

// checkmark.indicator should be visible when the checkbox is checked
input[type='checkbox']:checked ~ .checkmark::after {
  display: block;
}

.checkbox-label {
  color: $medium-gray;
  font-family: soleil, serif;
}
