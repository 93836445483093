// scss-lint:disable ImportantRule PseudoElement PropertySpelling QualifyingElement
@import '../settings';

textarea {
  resize: vertical;
}

label {
  &.required {
    &::after {
      color: get-color(alert);
      content: '\2022';
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1rem;
      margin-left: .25rem;
    }
  }
}

.form-wrapper {
  padding: 2rem 0;

  @include breakpoint(medium) {
    border-radius: $global-radius;
  }

  .question {
    color: $medium-gray;
    font-family: soleil, serif;
    padding-bottom: 1rem;

    .question-number {
      color: $primary-color;
      font-weight: bold;
    }
  }

  .group-name {
    font-family: soleil, serif;
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 1rem;
  }


  .form-header {
    font-family: soleil, serif;
    font-size: 1.5rem;
    margin-bottom: 0;
    padding: 1rem 0;
    text-align: left;
    text-transform: uppercase;
  }

  .form-content {
    padding: 2rem 0;
  }

  .form-field {
    color: $primary-color;
    font-family: soleil, serif;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    word-wrap: break-word;
  }

  .specify-form-field {
    color: rgba($primary-color, .75);
    font-family: soleil, serif;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 1.5rem;
    margin: .25rem 0 0;
    word-wrap: break-word;
  }

  .instruction-heading {
    height: 2.5rem;
  }

  .form-instructions {
    color: $medium-gray;
    font-family: calluna, serif;
  }

  .form-field-value {
    padding: 1rem 1rem 0;
  }

  .round-top-borders {
    border-top-left-radius: $global-radius;
    border-top-right-radius: $global-radius;
  }

  .round-bottom-borders {
    border-bottom-left-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
  }

  ::placeholder {
    color: $light-gray;
  }
}

form {
  border-radius: $global-radius;
  margin-bottom: 1rem;
}

.error-explanation {
  border: 1px solid get-color(alert);
  border-radius: $global-radius;
  box-shadow: inset 0 0 5px get-color(alert);
  margin-bottom: $global-margin;

  h2 {
    background: get-color(alert);
    font-size: $small-font-size;
    font-weight: bold;
    margin: 0;
    padding: .5 * $global-padding;
  }

  ul {
    margin: .5 * $global-margin 2 * $global-margin;
  }
}

// sass-lint:enable id-name-format, no-ids

.field-with-errors {
  label {
    color: darken(get-color(alert), 10%);
    font-weight: bold;

    &.message {
      font-size: $small-font-size;
      margin: -$global-margin 0 $global-margin;
    }
  }

  [type='color'],
  [type='date'],
  [type='datetime'],
  [type='datetime-local'],
  [type='email'],
  [type='month'],
  [type='number'],
  [type='password'],
  [type='search'],
  [type='tel'],
  [type='text'],
  [type='time'],
  [type='url'],
  [type='week'],
  select,
  textarea {
    border-bottom: 1px solid get-color(alert);
    box-shadow: 2px 1px 1px get-color(alert);
  }
}

.input-group-field {
  &.date {
    max-width: 150px;
  }
}

.input-group-button {
  &:nth-last-child(2) {
    button {
      border-radius: 0;
    }
  }
}

.text-other {
  margin-bottom: 0;
  margin-left: $global-margin;
}

.button {
  font-family: Soleil, serif;
}

.horizontal-line {
  border-bottom: 1px solid $light-gray;
  margin: 2rem 0 1rem;
}
