@import '../settings';

.radio-button-label {
  color: $medium-gray;
  font-family: soleil, serif;
}

input {
  &[type='radio'] {
    accent-color: $primary-color;
    margin: .5rem 1rem 1.5rem .5rem;
    transform: scale(2.3);

    &:checked {
      border: 8px solid get-color(success);
    }

    &:focus {
      outline: none;
    }

    // scss-lint:disable NestingDepth
    + label {
      &.button {
        margin-bottom: $global-margin;
      }
    }
  }

}
