@import '../settings';

.quote-images {
  display: block;
  margin-top: 5rem;
  position: relative;
  right: 0;
  top: 0;
}

.quote-background {
  margin-left: 20%;
  width: 80%;
}

.quote-callout {
  background-color: $white;
  border-left: 10px solid $primary-color;
  padding: 2rem;
  position: absolute;
  top: 80%;
  width: 50%;

  .quote-heading {
    font-family: 'din-condensed', serif;
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .quote-text {
    font-family: 'soleil', serif;
    font-size: 1.2rem;
  }
}
